<template>
  <div class="align-center t-center h100 father-center">
    <div class="congrats">
      <img src="../../../assets/images/EmptyTasks@3x.png" alt width="250" class="mt-5 mb-4" @load="$gtag('send', 'event', 'Conversão - Pagina de sucesso', 'Conversão - Pagina de sucesso', 'Conversão - Pagina de sucesso')"/>
      <div v-if="creditCardPaymentAprooved()">
        <h2 class="ma-0" style="color:#4c4d4f">
          Sua doação no valor total de
          <strong
            class="text-primary"
          >R$ {{ FormatterHelper.formatMoney(finishedCartTotalValue) }}</strong> foi aprovada
        </h2>
        <p class="f-size-16">
          Pedido nº
          <span class="text-blue">{{ finishedCartId }}</span>
          foi recebido com sucesso.
        </p>
      </div>
      <div v-else>
        <h2 class="ma-0" style="color:#4c4d4f">
          Seu pedido de doação nº
          <span class="text-blue">{{ finishedCartId }}</span>
        </h2>
        <p class="f-size-16">
          no valor total de
          <strong
            class="text-red"
          >R$ {{ FormatterHelper.formatMoney(finishedCartTotalValue) }}</strong> foi recebido com sucesso.
        </p>
      </div>
      <!-- <p
        class="f-size-16 mt-4"
      >Aguarde! Em breve você receberá em seu e-mail a confirmação do seu pagamento.</p>-->
      <h2 v-if="paymentMethodId == PaymentMethod.CreditCard" class="mt-5" style="color:#4c4d4f">
        <p
          v-if="cart != null && (cart.statusId == CartStatus.Approved || cart.statusId == CartStatus.ApprovedAndConfirmed)"
        >
          Esta doação foi aprovada pela operadora,
          <br />em breve você receberá um e-mail com a confirmação do pagamento.
        </p>
        <p v-else>
          Seu pagamento está em análise pela operadora,
          <br />em breve enviaremos um e-mail com a conclusão do pagamento.
        </p>
      </h2>
      <h2
        v-else
        class="mt-5"
        style="color:#4c4d4f"
      >Sua doação ficará pendente até a confirmação do pagamento. Você será notificado por email.</h2>
      <p class="f-size-16 mt-4">
        Compartilhe a campanha
        <span class="text-blue fw-600">{{ campaign ? campaign.name : '' }}</span>
        <br />através das mídias sociais abaixo.
      </p>
      <div class="social-media">
        <a
          :href="GetSocialMediaLink(SocialMedia.Whatsapp, campaign, hasProjectIncentivized ? 'incentivado' : 'semIncentivo')"
          class="wpp"
          target="_blank"
          @click="$gtag('send', 'event', 'Compartilhar WhatsApp', 'Compartilhar WhatsApp', 'Compartilhar WhatsApp')"
        >
          <Icon name="fab fa-whatsapp" size="18" colorFont="#fff" />
        </a>
        <a
          :href="GetSocialMediaLink(SocialMedia.Facebook, campaign, hasProjectIncentivized ? 'incentivado' : 'semIncentivo')"
          class="facebook"
          @click="$gtag('send', 'event', 'Compartilhar Facebook', 'Compartilhar Facebook', 'Compartilhar Facebook')"
        >
          <Icon name="fab fa-facebook-f" size="18" colorFont="#fff" />
        </a>
        <!-- <a
          href="javascript:;"
          class="instagram"
          onclick="gtag('send', 'event', 'Carrinho/Obrigado', 'clicou', 'Instagram')"
        >
          <Icon name="fab fa-instagram" size="18" colorFont="#fff" />
        </a>-->
        <a
          :href="GetSocialMediaLink(SocialMedia.Twitter, campaign, hasProjectIncentivized ? 'incentivado' : 'semIncentivo')"
          class="twitter"
          @click="$gtag('send', 'event', 'Compartilhar Twitter', 'Compartilhar Twitter', 'Compartilhar Twitter')"
        >
          <Icon name="fab fa-twitter" size="18" colorFont="#fff" />
        </a>
        <a
          :href="GetSocialMediaLink(SocialMedia.Linkedin, campaign, hasProjectIncentivized ? 'incentivado' : 'semIncentivo')"
          class="linkedin"
          @click="$gtag('send', 'event', 'Compartilhar LinkedIn', 'Compartilhar LinkedIn', 'Compartilhar LinkedIn')"
        >
          <Icon name="fab fa-linkedin" size="18" colorFont="#fff" />
        </a>
      </div>
      <div class="w-100 display-flex justify-space-between align-center mt-5">
        <button
          type="button"
          class="btn-ghost-secondary px-4 v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text"
          @click="gotoCampaignLP(); $gtag('send', 'event', 'Doação Ok', 'Voltar Campanha', 'Voltar para campanha')"
        >Voltar para a campanha</button>
        <button
          class="btn-blue px-4 v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text"
          @click="gotoUserDashboard(); $gtag('send', 'event', 'Doação Ok', 'Meu Painel', 'Meu Painel')"
        >{{ buttonMessage }}</button>
      </div>
    </div>
  </div>
</template>

<script type="plain/text">
import CartService from "@/scripts/services/cart.service";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import { Routes } from "@/scripts/models/enums/routes.enum";
import {
  SocialMedia,
  GetSocialMediaLink
} from "@/scripts/models/enums/socialMedias.enum";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import { mapGetters } from "vuex";
import UserService from "@/scripts/services/user.service";
import CartStatus from "@/scripts/models/enums/cartStatus.enum";

export default {
  data() {
    return {
      finishedCartId: 0,
      finishedCartTotalValue: 0,
      campaign: Object,
      FormatterHelper: new FormatterHelper(),
      cartService: new CartService(null),
      userService: new UserService(),
      SocialMedia: SocialMedia,
      GetSocialMediaLink: GetSocialMediaLink,
      hasProjectIncentivized: false,
      PaymentMethod: PaymentMethod,
      CartStatus: CartStatus,
      paymentMethodId: null,
      user: {},
      buttonMessage: "Minhas Doações",
      cart: {},
    };
  },
  created() {
    if (this.cartSuccess) {
      this.finishedCartId = this.cartSuccess.cartId;
      this.finishedCartTotalValue = this.cartSuccess.totalValue;
      this.campaign = this.cartSuccess.campaign;
      this.paymentMethodId = this.cartSuccess.paymentMethodId;

      this.cartService.getCartById(this.finishedCartId).then(data => {

        this.cart = data;

        if (data) {
          this.getUser(data.userId);
        }

        if (data && data.campaign && data.campaign.campaignProjectList) {
          this.hasProjectIncentivized =
            data.campaign.campaignProjectList.some((el) => el.project.incentiveLawId != null);
        }
        else {
          this.hasProjectIncentivized = false;
        }
      });

    } else {
      if (this.$route.query.id) {
        var cartId = this.$route.query.id;

        this.cartService.getCartById(cartId)
          .then(data => { this.loadCartDetails(data); })
          .catch(() => { this.$router.push({ name: Routes.app.CampaignLandingPage }); });

      } else {
        this.$router.push({ name: Routes.app.CampaignLandingPage });
      }
    }
  },
  computed: {
    ...mapGetters(["cartSuccess", "logged"]),
  },
  methods: {
    getUser(userId) {
      this.userService.findById(userId).then((data) => {
        if (data) {
          this.user = data;
          if (!data.username && !data.password && !data.lastLogin) {
            this.buttonMessage = "Criar Senha";
          }
        }
      });
    },
    loadCartDetails(data) {
      if (data) {
        this.cart = data;
        this.getUser(data.userId);

        this.finishedCartId = data.id;
        this.finishedCartTotalValue = data.totalValue;
        this.campaign = data.campaign;
        this.paymentMethodId = data.paymentMethodId;

        if (data && data.campaign && data.campaign.campaignProjectList) {
          this.hasProjectIncentivized =
            data.campaign.campaignProjectList
              .map(function (el) {
                return el.project.incentiveLawId;
              })
              .filter(function (r) {
                return r != null;
              }).length > 0;
        } else {
          this.hasProjectIncentivized = false;
        }
      }
    },
    gotoCampaignLP() {
      this.$router.push({ name: Routes.app.CampaignLandingPage });
    },
    gotoUserDashboard() {
      if (!this.user.userName && !this.user.password && !this.user.lastLogin) {
        this.$router.push("/registro-senha?tempHash=" + this.user.tempHash);
      }
      else {
        this.$router.push({ name: Routes.app.DashboardPF });
      }
    },
    creditCardPaymentAprooved() {
      return this.paymentMethodId == this.PaymentMethod.CreditCard
        && this.cart != null
        && (this.cart.statusId == this.CartStatus.Approved ||
          this.cart.statusId == this.CartStatus.ApprovedAndConfirmed);
    }
  }
};
</script>
<style lang="stylus" scoped>
.congrats {
  width: 100%;
  max-width: 520px;
  padding: 15px;
  padding-top: 50px;
}

@media (max-width: 400px) {
  .congrats {
    font-size: 12px;

    img {
      width: 50%;
    }

    .w-100 {
      flex-direction: column;

      button {
        width: 90%;
        margin: 8px 0;
      }
    }
  }
}
</style>